import { Loader } from 'src/design-system'
import * as React from 'react'
import { ColourVariantWithTheme } from '../../design-system/types/colour-variant'

type LoadingContentProps = {
  children: React.ReactNode
  className?: string
  loading: boolean
  variant?: ColourVariantWithTheme
}

export const LoadingContent = (props: LoadingContentProps) => {
  const { children, className, loading, variant } = props

  if (loading) return <Loader className={className} variant={variant} />

  return <>{children}</>
}
